import ProductService from "../services/productService";

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";

export const getProducts = () => {
  return  (dispatch) => {
     ProductService.getAllProducts()
      .then((res) => {
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_PRODUCTS_FAILED,
          payload: err,
        });
      });
  };
};
