import requests from "./httpService";

const ProductServices = {
  getFilteredProducts({ page, limit, category, title, price, ids }) {
    const searchCategory = category !== null ? category : "";
    const searchTitle = title !== null ? title : "";
    const searchPrice = price !== null ? price : "";
    const searchIds = ids?.toString();

    // let adminInfo = null;
    // if (Cookies.get('adminInfo')) {
    //   adminInfo = JSON.parse(Cookies.get('adminInfo'));
    // }
    // if(adminInfo) {
    //   if(adminInfo.role === CEO_ROLE) {
    //     return requests.get(
    //       `/products?page=${page}&limit=${limit}&category=${searchCategory}&title=${searchTitle}&price=${searchPrice}`
    //     );
    //   }
    //   if(adminInfo.role !== CEO_ROLE && adminInfo.cmsId) {
    //     return requests.get(
    //       `/products/getProductsWithCmsId?page=${page}&limit=${limit}&category=${searchCategory}&title=${searchTitle}&price=${searchPrice}&cmsId=${adminInfo.cmsId}`);
    //   }
    // }

    return requests.get(
      `/products/getProductsByStoreId?page=${page}&limit=${limit}&category=${searchCategory}&title=${searchTitle}&price=${searchPrice}&ids=${searchIds}`
    );
  },

  getAllProducts() {
    return requests.get("/products");
  },
  getStockOutProducts() {
    return requests.get("/products/stock-out");
  },

  getProductById(id) {
    return requests.post(`/products/${id}`);
  },

  addProduct(body) {
    return requests.post("/products/add", body);
  },

  addAllProducts(body) {
    return requests.post("/products/all", body);
  },

  updateProduct(id, body) {
    return requests.patch(`/products/${id}`, body);
  },

  updateStatus(id, body) {
    return requests.put(`/products/status/${id}`, body);
  },

  deleteProduct(id) {
    return requests.delete(`/products/${id}`);
  },
};

export default ProductServices;
