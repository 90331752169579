import {
  GET_BRANDS_SUCCESS,
} from "../actions/brandActions";

const initState = {
  brands: [],
};

const brandReducer = (state = initState, action) => {
  if (action.type === GET_BRANDS_SUCCESS) {
    return {
      ...state,
      brands: action.payload,
    };
  }

  return state;
};

export default brandReducer;
