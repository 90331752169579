import {
  GET_PRODUCTS_SUCCESS,

} from "../actions/productActions";

const initState = {
  products: [],
  allProducts: [],
};

const productReducer = (state = initState, action) => {
  if (action.type === GET_PRODUCTS_SUCCESS) {
    return {
      ...state,
      allProducts: action.payload,
    };
  } 
  return state;
};

export default productReducer;
