import categoryService from "../services/categoryService";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAILED = "GET_CATEGORIES_FAILED";

const fetchCategoriesSuccess = (categories) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: categories,
});

// fetch brands
export const fetchBrands = (brands) => {
  return (dispatch) => {
    dispatch(fetchCategoriesSuccess(brands));
  };
};
//////////////////

export const getCategories = () => {
  return (dispatch) => {
    categoryService.getAllCategory()
      .then((res) => {
        dispatch({
          type: GET_CATEGORIES_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CATEGORIES_FAILED,
          payload: err,
        });
      });
  };
};
