import {
  CATALOG_PRODUCTS_GET_SUCCESS,
  CATALOG_CATEGORIES_GET_SUCCESS,
} from "../actions/catalogProductsActions";

const initState = {
  catalogProducts: [],
  catalogCategories: [],
};

const productReducer = (state = initState, action) => {
  if (action.type === CATALOG_PRODUCTS_GET_SUCCESS) {
    return {
      ...state,
      catalogProducts: action.payload,
    };
  }
  if (action.type === CATALOG_CATEGORIES_GET_SUCCESS) {
    return {
      ...state,
      catalogCategories: action.payload,
    };
  }
  return state;
};

export default productReducer;
