import brandServices from "../services/brandServices";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";

export const GET_BRANDS_SUCCESS = "GET_BRANDS_SUCCESS";
export const GET_BRANDS_FAILED = "GET_BRANDS_FAILED";

const fetchBrandsSuccess = (brands) => ({
  type: FETCH_BRANDS_SUCCESS,
  payload: brands,
});

// fetch brands
export const fetchBrands = (brands) => {
  return (dispatch) => {
    dispatch(fetchBrandsSuccess(brands));
  };
};
//////////////////

export const getBrands = () => {
  return (dispatch) => {
    brandServices.getAllBrands()
      .then((res) => {
        dispatch({
          type: GET_BRANDS_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_BRANDS_FAILED,
          payload: err,
        });
      });
  };
};
