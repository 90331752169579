import Cookies from "js-cookie";
import requests from "./httpService";

const ProductServices = {
  getProductsByCategoryId(category) {
    return requests.get(`/catalogproduct/getProductsByCategoryId/${category}`);
  },

  getAllProducts() {
    return requests.get("/catalogproduct/");
  },
  getProductById(id) {
    return requests.get(`/catalogproduct/${id}`)
  },
  addProduct(body) {
    return requests.post("/catalogproduct/add", body);
  },
  updateProduct(id, body) {
    return requests.patch(`/catalogproduct/${id}`, body);
  },
};

export default ProductServices;
