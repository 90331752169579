import Cookies from "js-cookie";
import requests from "./httpService";

const CatalogCategoryService = {
  getCategoryById(id) {
    return requests.get(`/catalogcategory/${id}`);
  },
  getAllCategory() {
    return requests.get(`/catalogcategory`);
  },
  addCategory(body) {
    return requests.post("/catalogcategory/add", body);
  },

  updateCategory(id, body) {
    return requests.put(`/catalogcategory/${id}`, body);
  },

  updateStatus(id, body) {
    return requests.put(`/catalogcategory/status/${id}`, body);
  },

  deleteCategory(id) {
    return requests.patch(`/catalogcategory/${id}`);
  },
};

export default CatalogCategoryService;
