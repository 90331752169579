import requests from "./httpService";

const BrandServices = {
  getBrandById(id) {
    return requests.get(`/brand/${id}`);
  },
  getAllBrands() {
    return requests.get(`/brand`);
  },
  addBrand(body) {
    return requests.post("/brand/add", body);
  },

  updateBrand(id, body) {
    return requests.put(`/brand/${id}`, body);
  },

  deleteBrand(id, body) {
    return requests.patch(`/brand/${id}`, body);
  },
};

export default BrandServices;
