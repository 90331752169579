import catalogProductsActions from "../services/catalogProductService";
import catalogCategoryService from "../services/catalogCategoryService";

export const CATALOG_PRODUCTS_GET_SUCCESS = "CATALOG_PRODUCTS_GET_SUCCESS";
export const CATALOG_CATEGORIES_GET_SUCCESS = "CATALOG_CATEGORIES_GET_SUCCESS";
export const GET_CATALOG_PRODUCTS_FAILED = "GET_CATALOG_PRODUCTS_FAILED";
export const GET_CATALOG_CATEGORIES_FAILED = "GET_CATALOG_CATEGORIES_FAILED";

export const getProducts = () => {
  return (dispatch) => {
    catalogProductsActions
      .getAllProducts()
      .then((res) => {
        dispatch({
          type: CATALOG_PRODUCTS_GET_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CATALOG_PRODUCTS_FAILED,
          payload: err,
        });
      });
  };
};
export const getCategories = () => {
  return (dispatch) => {
    catalogCategoryService
      .getAllCategory()
      .then((res) => {
        dispatch({
          type: CATALOG_CATEGORIES_GET_SUCCESS,
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_CATALOG_CATEGORIES_FAILED,
          payload: err,
        });
      });
  };
};
