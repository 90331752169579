import productReducer from "./productReducer";
import brandReducer from "./brandReducer";
import categoryReducer from "./categoryReducer";
import catalogProductsReducer from "./catalogProductsReducer";
import { combineReducers } from "redux";
import { createMultilanguageReducer } from "redux-multilanguage";

const rootReducer = combineReducers({
  multilanguage: createMultilanguageReducer({ currentLanguageCode: "he" }),
  productData: productReducer,
  brandsData: brandReducer,
  categoryData: categoryReducer,
  catalogProductsData: catalogProductsReducer,
});

export default rootReducer;
