import requests from "./httpService";

const CategoryServices = {
  getCategoryById(id) {
    return requests.get(`/category/${id}`);
  },
  getAllCategory() {
    return requests.get(`/category`);
  },
  addCategory(body) {
    return requests.post("/category/add", body);
  },

  updateCategory(id, body) {
    return requests.put(`/category/${id}`, body);
  },

  updateStatus(id, body) {
    return requests.put(`/category/status/${id}`, body);
  },

  deleteCategory(id) {
    return requests.patch(`/category/${id}`);
  },
};

export default CategoryServices;
