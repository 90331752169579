import axios from "axios";
// import Cookies from "js-cookie";
// import { COOKIE_KEYS } from "../const";

// const BASE_URL = process.env.REACT_APP_API_BASE_URL;
const BASE_URL = "https://corepc-2e4c753354df.herokuapp.com/api";
console.log("BASE_URL =>", BASE_URL);

const instance = axios.create({
  // baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  baseURL: "https://corepc-2e4c753354df.herokuapp.com/api",
  timeout: 500000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add a request interceptor
// instance.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   let adminInfo;
//   if (Cookies.get(COOKIE_KEYS.ADMIN_INFO)) {
//     adminInfo = JSON.parse(Cookies.get(COOKIE_KEYS.ADMIN_INFO));
//   }

//   return {
//     ...config,
//     headers: {
//       authorization: adminInfo ? `Bearer ${adminInfo.token}` : null,
//       language: Cookies.get(COOKIE_KEYS.I18N) || "en",
//     },
//   };
// });

const responseBody = (response) => response.data;

const requests = {
  get: (url, body, headers) =>
    instance.get(url, body, headers).then(responseBody),

  post: (url, body) => instance.post(url, body).then(responseBody),

  put: (url, body, headers) =>
    instance.put(url, body, headers).then(responseBody),

  patch: (url, body) => instance.patch(url, body).then(responseBody),

  delete: (url) => instance.delete(url).then(responseBody),
};

export default requests;
